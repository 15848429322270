<template>
    <div>
      <rxNavBar title="实名认证"></rxNavBar>
        <div class="content">
            <!--            业主信息-->
            <div class="part">
                <div class="part-inputpart">
                    <div class="idCard-photo-frame">
                        <div class="idCard-photo" @click="showList">
                            <div class="idCard-photo-photo"></div>
                            <img src="../../../assets/images/icon-idcard-orange.png" style="width: 0.4rem;height: 0.3rem;margin-top: 0.12rem">
                            <div class="idCard-photo-text">已上传证件照</div>
                            <!--未上传证件照-->
                            <!--<div class="idCard-photo-text">未上传证件照</div>-->
                        </div>
                    </div>
                    <div class="part-inputpart-row">
                        <div :class=" 0==userName.trim().length ? 'content-none' : 'content-have'"></div>
                        <span class="part-inputpart-row-header">真实姓名</span>
                        <span class="content-divide">|</span>
                        <input v-model="userName" readonly>
                    </div>
                    <div class="part-inputpart-row">
                        <span :class=" 0==idCard.trim().length ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">{{certificateTypeStr}}</span>
                        <span class="content-divide">|</span>
                        <span style="color: #D8D8D8">{{idCard|idCardSecrecy}}</span>
<!--                        <input type="tel" v-model="idCard" readonly>-->
                    </div>
                </div>
            </div>
            <div @click="saveData" class="part part-button part-button-enabled ">重新认证</div>
        </div>
<!--        图片遮罩层-->
        <van-overlay :show="show" @click="show = false" :lock-scroll="false">
            <div class="wrapper" @click.stop>
                <img v-for="item in imgList" class="block" :src="item[1].path">
            </div>
        </van-overlay>
    </div>
</template>

<script>

    import {
        NavBar,
        Overlay
    } from 'vant'
    import {globaluserId, responseUtil} from "../../../libs/rongxunUtil";
    import {queryBaseData, queryIdPhoto} from "../../../getData/getData";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";



    export default {

        name: "RealNameAuthentication",

        components: {
            [NavBar.name]: NavBar,
            [Overlay.name]: Overlay,
          rxNavBar
        },

        data() {
            return {
                //是否显示遮罩层
                show: false,
                user_id: globaluserId(),
                userName: this.$route.query.userName || '',
                idCard: this.$route.query.certificateCode || '',
                imgList: [],
              certificateType: this.$route.query.certificateType || '',
              certificateTypeStr: '身份证'
            }
        },

        created() {
            this.initData()
          this.initDicData()
        },

        filters: {
          idCardSecrecy(value){
              let str = Array.from(value)
              for (let i=4;i<str.length-4;i++)
                  str[i] = '*'
              return str.join('')
          }
        },

        methods: {
            //初始化数据
            initData(){
                let that = this
                let initData = {
                    user_id: globaluserId()
                }
                queryIdPhoto(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        console.log(Object.entries(response.data.data))
                      if(response.data.data){
                        that.imgList = Object.entries(response.data.data)
                        //console.log(that.imgList[0][1].path)
                      }
                    })

                })
            },
          //初始化字典数据
          initDicData: function () {
            const that = this
            let initData = {}
            initData.fdName = ["CERTIFICATETYPEMAP"]
            queryBaseData(initData).then(function (response) {
              responseUtil.dealResponse(that, response, () => {
                //console.log(response.data.data)
                if(that.certificateType)
                 that.certificateTypeStr = response.data.data.CERTIFICATETYPEMAP[Number(that.certificateType)].dictionaryTitle
              })
            })
          },
            //重新认证
            saveData(){
                this.$router.push('RealNameAuthentication')
            },

            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },

            showList() {
                this.show = true
            }
        }


    }
</script>

<style scoped>

    /*隐藏滚动条*/
    ::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
    }

    .wrapper{
        display: flex;
        width: 100%;
        overflow-x: auto;
        margin-top: 200px;
    }
    .block{
        width: 260px;
        height: 165px;
        margin: 0 10px;
      object-fit: contain;
    }

    .content{
        margin-top: 5px;
    }

    .part {
        margin: 15px;
    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
    }

    .part-button{
        height: 45px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 45px;
        font-size: 15px;
        font-weight: 900;
        margin-top: 360px;
    }

    .part-button-enabled{
        background-image:linear-gradient(to right,#ffc274, #ff5d3b);
    }

    .part-inputpart div:last-child{
        border: none;
    }

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        align-items: center;
        height: 45px;
        background-color: #ffffff;
        margin-bottom: 15px;
    }

    .part-inputpart-row-header{
        font-weight: 900;
    }

    .part-inputpart-row input {
        border: 0;
        width: 100%;
    }

    .part-inputpart-row input::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-inputpart-row-housetype input{
        width: 18px;
        margin-left: 10px;
    }

    .part-inputpart-row-right{
        float: right;
        margin-right: 10px;
    }

    .part-inputpart-row-right-downArrow{
        width: 9px;
    }

    .part-inputpart-row-right-upArrow{
        width: 9px;
        transform: rotateX(180deg);
    }

    .content-have {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }
    /*上传证件照提示*/
    .idCard-photo {
        width: 100%;
        height: 30px;
        margin-bottom: 5px;
        margin-right: 5px;
    }

    .idCard-photo {
        float: right;
        width: 95px;
        height: 20px;
    }

    .idCard-photo-text {
        float: right;
        font-size: 12px;
        color: #FF5D3B;
        font-family: PingFangSC-Semibold;
        font-weight: 400;
    }


</style>
